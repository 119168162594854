import http from "@/http";
import eventBus from "@/events";

const DieselControl = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sort = ctx.sort || 'company';
            const sortDesc = ctx.sortDesc ? 'desc' : 'asc';
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/dieselControl/search/all", {params});
            eventBus.$emit('LIST_COUNT_DIESEL_CONTROL_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.dieselControl;
        } else {
            const resp = await http("/dieselControl/search/all");
            eventBus.$emit('LIST_COUNT_DIESEL_CONTROL_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.dieselControl;
        }
    },
    create: function(data) {
        return http.post('/dieselControl', data);
    },
    update: function (id, data) {
        return http.patch('/dieselControl/' + id, data);
    },
    findById(id) {
        return http.get('/dieselControl/' + id);
    },
    enable(id) {
        return http.patch('/dieselControl/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/dieselControl/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/dieselControl/' + id)
    },
    findTurns: function(id) { return http.get('undefined'.replace('{id}', id)); },findRoutes: function(id) { return http.get('undefined'.replace('{id}', id)); },findDrivers: function(id) { return http.get('undefined'.replace('{id}', id)); },findCompany: function(id) { return http.get('undefined'.replace('{id}', id)); },findTravels: function(id) { return http.get('undefined'.replace('{id}', id)); }
};

export default DieselControl;
